import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { t } from './Util_format';
import { cellTextStyle } from './PageLiabilities';
import config from './config/env.json';

export function TableLiabilitiesAll({ URLliabilityType, liabilities, onPageChange, searchTerm, category, base_currency }) {
  const [sortConfig, setSortConfig] = useState({ key: 'user_liability_type', direction: 'asc' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const navigate = useNavigate();

  const handleRedirectToLiabilityType = (liabilityType) => {
    navigate('/liabilities/' + liabilityType); 
  }

  const requestSort = (key, type) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction, type });
  };

  const TableSort = (a, b, sortConfig) => {
    const { key, direction, type } = sortConfig;
    const resolvePath = (object, path, defaultValue) => {
      return path.split('.').reduce((o, p) => o ? o[p] : defaultValue, object);
    };
  
    let valA = resolvePath(a, key, null);
    let valB = resolvePath(b, key, null);
  
    // Apply translation if sorting by 'user_liability_type' and type is 'string'
    if (key === 'user_liability_type' && type === 'string') {
      valA = valA ? t('categories_liabilities_' + valA).toLowerCase() : '';
      valB = valB ? t('categories_liabilities_' + valB).toLowerCase() : '';
    } else {
      // Normalize values for sorting for other cases
      switch (type) {
        case 'currency':
          valA = valA ? parseCurrencyValue(valA) : 0;
          valB = valB ? parseCurrencyValue(valB) : 0;
          break;
        case 'number':
          valA = parseNumberValue(valA);
          valB = parseNumberValue(valB);
          break;
        case 'string':
          valA = valA ? valA.toLowerCase() : '';
          valB = valB ? valB.toLowerCase() : '';
          break;
        default:
          break;
      }
    }
  
    // Sort logic
    if (valA < valB) {
      return direction === 'asc' ? -1 : 1;
    }
    if (valA > valB) {
      return direction === 'asc' ? 1 : -1;
    }
    return 0;
  };
  
  function parseCurrencyValue(value) {
    const num = Number(value.replace(/[^0-9.-]+/g, ""));
    return isNaN(num) || !isFinite(num) ? Infinity : num;
  }

  function parseNumberValue(value) {
    const num = parseFloat(value);
    return isNaN(num) || !isFinite(num) ? Infinity : num;
  }

  const renderTableSort = (key, label, type) => (
    <TableSortLabel
      active={sortConfig.key === key}
      direction={sortConfig.key === key ? sortConfig.direction : 'asc'}
      onClick={() => requestSort(key, type)}
    >
      {t(label)}
    </TableSortLabel>
  );

  if (!category) {
    return (
      <Typography variant="body1" style={{ marginTop: '40px' }}>
        {t('please_select_the_liability_category')}
      </Typography>
    );
  } else if (!Array.isArray(liabilities) || liabilities.length === 0) {
    return (
      <Typography variant="body1" style={{ marginTop: '40px' }}>
        {t('no_liabilities_to_show')}
      </Typography>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedLiabilities = [...liabilities].sort((a, b) => {
    return TableSort(a, b, sortConfig);
  });

  const filteredLiabilities = sortedLiabilities.filter(liability => {
    // prevent error switching between shares and bank accounts
    if (!liability.last_value) {
      return false;
    }

    if (!liability.user_liability_type) {
      return false;
    }

    liability.table_total_value = parseFloat(liability.last_value[base_currency]).toLocaleString('en-US', { style: 'currency', currency: base_currency });
    liability.table_PL_unit = parseFloat(liability.PL_unit[base_currency]).toLocaleString('en-US', { style: 'currency', currency: base_currency });

    // Find a category with a similar user_liability_type
    const category = config.LIABILITY_CATEGORIES.find(c =>
      c.user_liability_type.toLowerCase().includes(liability.user_liability_type.toLowerCase())
    );

    // Use the category title if available, or default to liability's user_liability_type
    const searchField = category ? t('categories_liabilities_' + category.user_liability_type) : liability.user_liability_type.toLowerCase();

    return searchField.includes(searchTerm.toLowerCase());
  });

  const displayedLiabilities = filteredLiabilities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div>
      <Table style={{ marginTop: '20px' }}>
        <TableHead>
          <TableRow>
            <TableCell data-key='user_liability_type' data-type='string'>
              {renderTableSort('user_liability_type', t('category'), 'string')}
            </TableCell>
            <TableCell data-key='table_total_value' data-type='currency' style={{ textAlign: 'right', width: '150px' }}>
              {renderTableSort('table_total_value', t('total_value'), 'currency')}
            </TableCell>
            <TableCell data-key='table_PL_unit' data-type='currency' style={{ textAlign: 'right', width: '150px' }}>
              {renderTableSort('table_PL_unit', t('total_PL'), 'currency')}
            </TableCell>
            {/* Add additional headers as needed */}
          </TableRow>
        </TableHead>

        <TableBody>
          {displayedLiabilities.map((liability) => (
            <TableRow key={liability.user_liability_id} onClick={() => handleRedirectToLiabilityType(liability.user_liability_type)} style={{ cursor: 'pointer' }}>
              <TableCell style={{ textAlign: 'left', ...cellTextStyle }}>{t('categories_liabilities_' + config.LIABILITY_CATEGORIES.find(c => c.user_liability_type === liability.user_liability_type).user_liability_type)}</TableCell>
              <TableCell style={{ textAlign: 'right', ...cellTextStyle, paddingRight: '40px', width: '150px' }}>
                {liability.table_total_value}
              </TableCell>
              <TableCell style={{ textAlign: 'right', ...cellTextStyle, paddingRight: '40px', width: '150px' }}>
                {liability.table_PL_unit}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        style={{ marginTop: '20px' }}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredLiabilities.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className='table_bottom'
      />
    </div>
  );
}

import React, { useState } from 'react';
import { Typography, Link, CircularProgress, ThemeProvider , TextField, Button, Box} from '@mui/material';
import { getAuth } from "firebase/auth";
import { useLocation } from 'react-router-dom';
import { deleteCookie } from './App';
import { t } from './Util_format';
import { sendUserEmailVerification } from './Util_API_calls';
import { theme,  LoginCentralPic, UnauthenticatedMainContainer, UnauthenticatedLeftContainer, UnauthenticatedRightContainer, ErrorContainer, SuccessContainer } from '@style/styled.js';

function Resend_registration_email() {
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const emailFromQuery = queryParams.get('email') || '';
  const [email, setEmail] = useState(emailFromQuery);

  const handleResendRegistrationEmail = async () => {
    setIsProcessing(true);
    setSuccessMessage("");
    setError("");
    deleteCookie();

    if (!email.trim()) {
      setError(t("please_insert_email"));
      setIsProcessing(false);
      return;
    }

    const auth = getAuth();

    try {
      const user = auth.currentUser;
      if (user && !user.emailVerified) {
        await sendUserEmailVerification(user.uid);
        setSuccessMessage(t("registration_email_sent"));
      } else {
        setError(t("user_not_found_or_already_verified"));
      }
    } catch (err) {
      setError(err.message);
      if (err.message === "Firebase: Error (auth/too-many-requests).") {
        setError(t("too_many_requests_try_later"));
      } else {
        setError(err.message);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box id="PageResendRegistrationEmail">
        <UnauthenticatedMainContainer>
          <UnauthenticatedLeftContainer>
            <LoginCentralPic></LoginCentralPic>
          </UnauthenticatedLeftContainer>

          <UnauthenticatedRightContainer>
            <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
              {t("resend_registration_title")}
            </Typography>

            <Typography variant="body2" gutterBottom style={{ textAlign: 'center' }}>
              {t("resend_registration_description")}
            </Typography>
            {error && (
              <ErrorContainer isModal={false}>{error}</ErrorContainer>
            )}
            {successMessage &&
              <SuccessContainer isModal={false}>{successMessage}</SuccessContainer>
            }
            <TextField
              label={t("email_address")}
              variant="outlined"
              fullWidth
              margin="normal"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !isProcessing) {
                  handleResendRegistrationEmail();
                }
              }}
            />
            <Button
  
              sx={{
                textTransform: "none",
                backgroundColor: isProcessing
                  ? "#AAAAAA !important"
                  : undefined,
                color: isProcessing ? "#FFFFFF !important" : undefined,
                minWidth: "100%",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              onClick={handleResendRegistrationEmail}
              disabled={isProcessing}
            >
              {isProcessing ? <CircularProgress size={24} color="inherit" /> : t("send_registration_email")}
            </Button>
            <Link href="/login" >
              <Typography variant="body2" >
                {t('back_to_login')}
              </Typography>
            </Link>
          </UnauthenticatedRightContainer>
        </UnauthenticatedMainContainer>
      </Box>
    </ThemeProvider>
  );
}

export default Resend_registration_email;
